import { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from './shared/apollo/ApolloProvider';
import { ENV_VARS_SETUP_IS_READY } from './shared/constants/index';
import { Auth0Provider } from './modules/auth/Auth0Provider';
import { Routes } from './routes/routes';
import { Loader } from './shared/components/MainLoader';
import ThemeProvider from './shared/boost/ThemeProvider';
import './shared/css/App.css';
import {
  AppUpdateDialog,
  OnDialogChange,
} from './shared/components/AppUpdateDialog';
import * as serviceWorker from './serviceWorker';
import { lazyWithRetry } from './shared/components/LazyWithRetry';
// This componet is use like to avoid load it if is not needed
const Component = lazyWithRetry(() => import('./shared/components/DefaulView'));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

/**
 * Main app component.
 *
 * @returns {JSX.Element} - Main app component.
 */
function App(): JSX.Element {
  useEffect(() => {
    window.toastRefreshPage = () => {
      toast.info(
        'A new version of the platform is available. Reloading app...',
      );
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload();
    };
  }, []);
  return (
    <BrowserRouter>
      <ThemeProvider>
        {ENV_VARS_SETUP_IS_READY ? (
          <Auth0Provider>
            <ApolloProvider>
              <Routes />
              <AppUpdateDialog />
            </ApolloProvider>
          </Auth0Provider>
        ) : (
          <Suspense fallback={<Loader />}>
            <Component />
          </Suspense>
        )}
      </ThemeProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={10000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </BrowserRouter>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => OnDialogChange.dispatch(true),
});

// eslint-disable-next-line import/no-default-export
export default App;
