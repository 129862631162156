import React, { useState, useCallback } from 'react';
import { createEvent, useEvent } from '@cobuildlab/react-simple-state';
import { Dialog, Grid, Button, Text } from '@cobuildlab/boost';
import styled from 'styled-components';

const FlexBox = styled.div`
  width: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const OnDialogChange = createEvent<boolean>();

export const AppUpdateDialog: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const open = useEvent(OnDialogChange);

  const onRefresh = useCallback(async () => {
    setLoading(true);

    if (window.caches) {
      console.log('cache:', window.caches);

      const caches = await window.caches.keys();
      console.log('caches:', caches);

      caches.forEach((cache) => window.caches.delete(cache));
    } else {
      console.log('no-cache:', window.caches);
    }

    window.location.reload();

    window.location.reload();
  }, []);

  const onCancel = useCallback(() => {
    OnDialogChange.dispatch(false);
  }, []);

  return (
    <Dialog isOpen={open}>
      <Dialog.Header title="New update" onClose={onCancel} />
      <Dialog.Body scrollable>
        <Grid.Layout gap="sm" stretch>
          <Grid.Box>
            <Text>
              There is a new update available for the app. If you want to get
              the newest version, please close all other tabs on this site and
              refresh this page
            </Text>
          </Grid.Box>
        </Grid.Layout>
      </Dialog.Body>
      <Dialog.Footer>
        <FlexBox>
          <div style={{ marginRight: '15px' }}>
            <Button disabled={loading} onClick={onCancel}>
              CANCEL
            </Button>
          </div>
          <Button color="primary" disabled={loading} onClick={onRefresh}>
            REFRESH
          </Button>
        </FlexBox>
      </Dialog.Footer>
    </Dialog>
  );
};
