import React, { useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { Loader } from '../../shared/components/ui/Loader';

export const SalesForceView: React.FC = () => {
  const path = useRef(window.location.href);
  useEffect(() => {
    const url = new URL(path.current);
    const code = url.searchParams.get('code');

    if (window.opener && window.opener.salesforceConnected) {
      window.opener.salesforceConnected(code);
    }

    window.close();
  }, []);

  return (
    <div className="LoaderMain">
      <Loader />
      <Redirect to="/" />
    </div>
  );
};
