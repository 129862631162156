import * as jsforce from 'jsforce';
import { createEvent } from '@cobuildlab/react-simple-state';
import { SalesforceConnection } from '@cobuildlab/salezio-shared';

export type SalesforceConnectedEventType = {
  salesforceConnection: Required<SalesforceConnection>;
  connection: jsforce.Connection;
};

export const salesforceConnectedEvent = createEvent<SalesforceConnectedEventType | null>();
export const salesforceConnectionLoadingEvent = createEvent<boolean>({
  initialValue: false,
});
export const salesforceConnectedError = createEvent<Error>();

export const salesforceConnectionCreatedEvent = createEvent<string>();
export const salesforceConnectionCreatedError = createEvent<Error>();

export const salesforceLogsEvent = createEvent<
  Array<{
    id: string;
    createdAt: string;
    level: string;
    message: string;
  }>
>();
export const salesforceLogsError = createEvent<Error>();

export const salesforceUpdateNow = createEvent<boolean>();

export const connectionInvalid = createEvent<boolean>();

export const salesforceUpdateNowEvent = createEvent<{
  success: boolean;
}>();
export const salesforceUpdateNowErrorEvent = createEvent<Error>();

export const salesforceFieldMappingCreateEvent = createEvent<{
  id: string;
  object: string;
  leadField: string;
  salesforceField: string;
}>();
export const salesforceFieldMappingCreateErrorEvent = createEvent<Error>();

export const salesforceFieldMappingDeleteEvent = createEvent<{
  success: string;
}>();
export const salesforceFieldMappingDeleteErrorEvent = createEvent<Error>();
export type SalesforceMappingType = {
  id: string;
  object: string;
  leadField: string;
  salesforceField: string;
};
export const salesforceFieldMappingUpdateEvent = createEvent<SalesforceMappingType>();
export const salesforceFieldMappingUpdateErrorEvent = createEvent<Error>();

export const salesforceFieldMappingsEvent = createEvent<
  Array<SalesforceMappingType>
>();
export const salesforceFieldMappingsErrorEvent = createEvent<Error>();

export const salesforceMissingFieldsEvent = createEvent<string>();

export const salesforceObjectFieldsEvent = createEvent<{
  opportunity: string[];
}>();
export const salesforceObjectFieldsErrorEvent = createEvent<Error>();
