import { Event, useEvent } from '@cobuildlab/react-simple-state/';
import * as jsforce from 'jsforce';

import {
  check,
  CompanyRoleNames,
  PermissionsNames,
} from '@cobuildlab/salezio-shared';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ValidationError } from 'yup';
import { useSession } from '../../modules/session/session-hooks';
import { initializeSalesforce } from '../../modules/settings/salesforce/salesforce-actions';
import {
  connectionInvalid,
  salesforceConnectedEvent,
} from '../../modules/settings/salesforce/salesforce-events';
import { getStateFromSearchStringQuery } from './functions';
/**
 * @description - Get current permissions.
 * @param {PermissionsNames} permission - Permissions Name.
 * @param extraData - Extra data for validtors in rules.
 * @param {boolean} loadding - Wait for ready for use.
 * @returns {Array<boolean, string>} - Return a array the first element is the confirmation, the second element is a error message and the last element is current user role.
 */
export const useRoleAuth = (
  permission: PermissionsNames,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData?: Record<any, any>,
  loadding?: boolean,
): [boolean, string, string?] => {
  const session = useSession();

  if (loadding || !session) return [true, ''];

  const data = { user: session?.user, companyId: session?.selectedCompany.id };
  if (extraData) {
    Object.assign(data, extraData);
  }

  return check(
    session?.selectedCompany?.role || CompanyRoleNames.MEMBER,
    permission,
    data,
  );
};

type Props = {
  deafultPage?: number | undefined;
  deafultPageSize?: number | undefined;
};

type OnChaneParams = {
  page?: number;
  pageSize?: number;
};
/**
 * @param {number} deafultPage  - Default page to render.
 * @returns {[number,Function]} - State tuple.
 */
export function usePagination({
  deafultPage = 1,
  deafultPageSize = 10,
}: Props = {}): [
  { page: number; pageSize: number },
  (n: OnChaneParams) => void,
] {
  const location = useLocation();
  const history = useHistory();

  const [page, setPage] = useState(() => {
    const query = new URLSearchParams(location.search);
    const currentPage = query.get('page')
      ? Number(query.get('page'))
      : deafultPage;
    const currentPageSize = query.get('pageSize')
      ? Number(query.get('pageSize'))
      : deafultPageSize;

    return {
      page: currentPage,
      pageSize: currentPageSize,
    };
  });

  // useEffect(() => {
  //   setPage((prev) => ({ ...prev, pageSize: deafultPageSize }));
  // }, [deafultPageSize]);

  // useEffect(() => {
  //   setPage((prev) => ({ ...prev, page: deafultPage }));
  // }, [deafultPage]);

  const onChange = useCallback(
    (value: OnChaneParams): void => {
      const query = new URLSearchParams(location.search);
      if (value.page) {
        query.set('page', value.page.toString());
      }
      if (value.pageSize) {
        query.set('pageSize', value.pageSize.toString());
      }

      const push = {
        pathname: location.pathname,
        search: query.toString(),
      };
      history.push(push);

      setPage((prev) => ({ ...prev, ...value }));
    },
    [history, location.pathname, location.search],
  );
  return [page, onChange];
}

/**
 * @param errorEvent - Error event to susbcribe.
 * @returns Form Error state.
 */
export function useFormError<T extends Record<string, string>>(
  errorEvent: Event<ValidationError, ValidationError>,
): Partial<T> {
  const [state, setState] = useState<Partial<T>>({});

  useEffect(() => {
    const sub = errorEvent.subscribe((data) => {
      const errors: Partial<T> =
        data?.inner.reduce((prev: Partial<T>, current) => {
          const key = current.path as keyof T;
          const newObj = { ...prev, [key]: current.message };

          return newObj;
        }, {}) ?? {};
      setState(errors);
    });

    return () => sub.unsubscribe();
  }, [errorEvent]);

  return state;
}

type SetQueryParamsCallback<T> = (key: keyof T, value: T[keyof T]) => void;
/**
 * @param keys - Key to get from query params.
 * @param defaultValues - Default values to.
 * @returns Tupla with the query params and a callback to update it.
 */
export function useQueryParams<T extends Record<string, string>>(
  keys: (keyof T)[],
  defaultValues: T,
): [T, SetQueryParamsCallback<T>] {
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useState<T>(() =>
    getStateFromSearchStringQuery(location.search, keys, defaultValues),
  );
  const routerRef = useRef({ location, history, keys });
  routerRef.current = { location, history, keys };
  useEffect(() => {
    setState(() =>
      getStateFromSearchStringQuery(
        location.search,
        routerRef.current.keys,
        {} as T,
      ),
    );
  }, [location.search]);

  const onChange: SetQueryParamsCallback<T> = useCallback((key, value) => {
    const query = new URLSearchParams(routerRef.current.location.search);

    query.set(key.toString(), value);

    const push = {
      pathname: routerRef.current.location.pathname,
      search: query.toString(),
    };
    routerRef.current.history.push(push);
  }, []);

  return [state, onChange];
}

/**
 * @returns Boolean to validate salesforce connection.
 */
export function useCheckSalesforceConnection(): boolean {
  const [isValid, setIsValid] = useState(true);
  const salesForceData = useEvent(salesforceConnectedEvent);
  const isConnectionInvalid = useEvent(connectionInvalid);

  useEffect(() => {
    try {
      const savedConnection = localStorage.getItem('salesforceConnection');
      console.log('savedConnection:', savedConnection);

      const salesforceConnection =
        savedConnection && savedConnection.length > 0
          ? JSON.parse(savedConnection ?? '{}')
          : null;

      const { accessToken, instanceUrl } = salesforceConnection || {};

      const connection = new jsforce.Connection({
        accessToken,
        instanceUrl,
      });

      const data = salesforceConnection
        ? { connection, salesforceConnection }
        : salesForceData;
      setIsValid(!(!data || isConnectionInvalid));
    } catch (error) {
      setIsValid(!(!salesForceData || isConnectionInvalid));
    }
  }, [salesForceData, isConnectionInvalid]);

  useEffect(() => {
    initializeSalesforce();
  }, []);

  return isValid;
}

/**
 * @param delay - Delay of the callbacl.
 * @param waitDelay - If it should wait to run the next callback.
 * @returns Callback to be called with delay.
 */
export function useDebounceCallback(
  delay: number,
  waitDelay = false,
): (callback: () => void) => void {
  const timeoutRef = useRef<number | undefined>();

  return useCallback(
    (callback) => {
      if (timeoutRef.current && waitDelay) {
        window.clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(callback, delay);
    },
    [delay, waitDelay],
  );
}
