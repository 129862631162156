import { useEvent } from '@cobuildlab/react-simple-state';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { THEME_COLORS } from '../../constants/colors';
import { ROUTES } from '../../constants/menu-routes';
import { OnBreabcrumbText } from './breabcrumb-event';

const BreabcrumbComtainer = styled.div`
  width: calc(100wh -56px);
  grid-area: breadcrumb;
  height: 32px;
  padding: 5px 20px;
  box-shadow: 0px 2px 4px #a3a3a355;
  background-color: var(--white);
`;
const TextComtainer = styled.div`
  display: flex;
  & div {
    padding: 0 5px;
  }
`;
const Text = styled.span<{ weight?: string }>`
  color: var(--dark-slate);
  font-weight: ${({ weight }) => weight};
  &:hover {
    color: var(--primary);
    cursor: pointer;
  }
`;
const TextNoHover = styled.span<{ weight?: string }>`
  color: var(--dark-slate);
  font-weight: ${({ weight }) => weight};
`;
/**
 *  Dashoard View for App.
 *
 * @returns {React.ReactElement} - React element.
 */
export function Breabcrumb(): ReactElement {
  const history = useHistory();
  const links = useEvent(OnBreabcrumbText, {
    initialValue: [
      {
        text: 'Salez.io',
        route: ROUTES.DASHBOARD.path,
      },
    ],
    reducer(link) {
      return (
        link || [
          {
            text: 'Salez.io',
            route: ROUTES.DASHBOARD.path,
          },
        ]
      );
    },
  });
  return (
    <BreabcrumbComtainer>
      <TextComtainer>
        {links.map((link, index) => (
          <>
            <div key={`${link.text}1`}>
              <Text onClick={() => history.push(link.route)}>{link.text}</Text>
            </div>
            {index !== links.length - 1 ? (
              <div key={`${link.text}2`}>
                <TextNoHover weight="bold" color={THEME_COLORS.DARK}>
                  {'>'}
                </TextNoHover>
              </div>
            ) : null}
          </>
        ))}
      </TextComtainer>
    </BreabcrumbComtainer>
  );
}
