import * as jsforce from 'jsforce';
// import { toast } from 'react-toastify';
import {
  Company,
  SalesforceConnection,
  SalesforceConnectionUpdateInput,
} from '@cobuildlab/salezio-shared';
import { isValidString } from '@cobuildlab/validation-utils';
import {
  SALESFORCE_CONNECTION_LIST_QUERY,
  SALESFORCE_CONNECTION_UPDATE_MUTATION,
  SALESFORCE_FIELD_MAPPING_CREATE_MUTATION,
  SALESFORCE_FIELD_MAPPING_DELETE_MUTATION,
  SALESFORCE_FIELD_MAPPING_LIST_QUERY,
  SALESFORCE_FIELD_MAPPING_UPDATE_MUTATION,
  SALESFORCE_LOG_LIST_QUERY,
  SALESFORCE_OAUTH2_AUTHORIZATION_RESOLVER,
  SALESFORCE_UPDATE_NOW_MUTATION,
} from './salesforce-queries';
import { OnSession } from '../../session/session-events';
import { apolloClient } from '../../../shared/apollo/index';
import {
  salesforceConnectedEvent,
  salesforceConnectedError,
  salesforceConnectionLoadingEvent,
  salesforceConnectionCreatedError,
  salesforceConnectionCreatedEvent,
  salesforceLogsEvent,
  salesforceLogsError,
  // salesforceMissingFieldsEvent,
  salesforceObjectFieldsEvent,
  salesforceObjectFieldsErrorEvent,
  salesforceFieldMappingsEvent,
  salesforceFieldMappingsErrorEvent,
  salesforceFieldMappingDeleteErrorEvent,
  salesforceFieldMappingDeleteEvent,
  salesforceFieldMappingUpdateErrorEvent,
  salesforceFieldMappingUpdateEvent,
  salesforceFieldMappingCreateErrorEvent,
  salesforceFieldMappingCreateEvent,
  salesforceUpdateNowEvent,
  salesforceUpdateNowErrorEvent,
  SalesforceConnectedEventType,
  SalesforceMappingType,
} from './salesforce-events';
import {
  // SALESFORCE_CUSTOM_FIELD_LABEL,
  SALESFORCE_CONNECTION_STATUS_ACTIVE,
  SALESFORCE_CONNECTION_STATUS_INACTIVE,
  SALESFORCE_OPPORTUNITY_REMOVE_FIELDS,
} from './salesforce-utils';
import {
  SALESFORCE_CLIENT_ID,
  SALESFORCE_REDIRECT_URI,
} from '../../../shared/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let window: Window &
  typeof globalThis & { salesforceConnected: (code: string) => void };

const popupWin = (url: string): void => {
  const windowWidth = 912;
  const windowHeight = 513;
  const left = window.screen.width / 2 - windowWidth / 2;
  const top = window.screen.height / 2 - windowHeight / 2;
  window.open(
    url,
    '_new',
    `location=yes,toolbar=no,status=no,menubar=no,width=${windowWidth},height=${windowHeight},top=${top},left=${left}`,
  );
};

export const salesforceLogin = (): void => {
  const oauth2 = new jsforce.OAuth2({
    clientId: SALESFORCE_CLIENT_ID,
    redirectUri: SALESFORCE_REDIRECT_URI,
  });
  const url = oauth2.getAuthorizationUrl({ scope: 'api id web refresh_token' });
  popupWin(url);
};

export const fetchSalesforceConnection = async (
  useCache?: boolean,
): Promise<void> => {
  const storedConnection = salesforceConnectedEvent.get();

  if (storedConnection && !useCache) return;

  const client = apolloClient;

  const session = OnSession.get();

  const filter = {
    status: { equals: SALESFORCE_CONNECTION_STATUS_ACTIVE },
    company: { id: { equals: session?.selectedCompany?.id } },
  };

  try {
    const {
      data: { salesforceConnectionsList },
    } = await client.query({
      query: SALESFORCE_CONNECTION_LIST_QUERY,
      variables: { filter },
      fetchPolicy: 'network-only',
    });
    const [salesforceConnection] = salesforceConnectionsList.items;

    localStorage.setItem(
      'salesforceConnection',
      JSON.stringify(salesforceConnection),
    );
    if (salesforceConnection) {
      const { accessToken, instanceUrl } = salesforceConnection;

      const connection = new jsforce.Connection({
        accessToken,
        instanceUrl,
      });

      // IMPORTANT
      // ADD CONNECTION VERIFICATION STEPS

      salesforceConnectedEvent.dispatch({ connection, salesforceConnection });
    } else salesforceConnectedEvent.dispatch(null);
  } catch (error) {
    console.error('fetchSalesforceConnection:error', error);
    salesforceConnectedError.dispatch(error);
  }
};

export const validateSalesforceConnection = (
  code: string,
  company: (Omit<Company, never> & Required<Pick<Company, 'id'>>) | undefined,
): [boolean, string] => {
  if (!isValidString(code)) {
    return [false, 'Invalid salesforce authorization code'];
  }

  if (!(company && company.id)) {
    return [false, 'You must have an company'];
  }

  return [true, ''];
};

export const authorizeConnection = async (code: string): Promise<void> => {
  salesforceConnectionLoadingEvent.dispatch(true);
  const client = apolloClient;
  const session = OnSession.get();
  const selectedCompany = session?.selectedCompany;

  const [isValid, message] = validateSalesforceConnection(
    code,
    selectedCompany,
  );

  if (!isValid) {
    salesforceConnectionCreatedError.dispatch(new Error(message));
    return;
  }

  const data = { code, companyId: selectedCompany?.id };

  try {
    const {
      data: { salesforceOauth2Callback },
    } = await client.query({
      query: SALESFORCE_OAUTH2_AUTHORIZATION_RESOLVER,
      variables: { data },
      fetchPolicy: 'network-only',
    });
    console.log('salesforceOauth2Callback', { salesforceOauth2Callback });
    const { success, messageOauth } = salesforceOauth2Callback;

    if (!success) {
      salesforceConnectionLoadingEvent.dispatch(false);
      throw new Error(messageOauth);
    }
  } catch (error) {
    console.error(error);
    salesforceConnectionCreatedError.dispatch(
      new Error('There was an validation error with salesforce'),
    );
    return;
  }

  salesforceConnectionCreatedEvent.dispatch(
    'Connection with salesforce established',
  );
};

const loginListener = (): void => {
  window.salesforceConnected = (code) => {
    authorizeConnection(code);
  };
};

export const initializeSalesforce = (cache?: boolean): void => {
  // ADD SALESFORCE LOGIN LISTENER
  loginListener();
  fetchSalesforceConnection(cache);
};

const updateSalesforceConnectionLastUpdate = (lastUpdate: string): void => {
  const salesforceConnectionEvent = salesforceConnectedEvent.get();

  if (
    salesforceConnectionEvent &&
    salesforceConnectionEvent.salesforceConnection.lastUpdate !== lastUpdate
  ) {
    console.log('Update Last Update:', lastUpdate);

    const updatedSalesforceConnection = {
      ...salesforceConnectionEvent,
      salesforceConnection: {
        ...salesforceConnectionEvent.salesforceConnection,
        lastUpdate,
      },
    };

    salesforceConnectedEvent.dispatch(updatedSalesforceConnection);
  }
};

export const fetchSalesforceLogs = async (): Promise<Array<{
  id: string;
  createdAt: string;
  level: string;
  message: string;
}> | null> => {
  const client = apolloClient;
  const connection = salesforceConnectedEvent.get();

  if (connection) {
    const {
      salesforceConnection: { id: salesforceConnectionId },
    } = connection;

    try {
      const {
        data: { salesforceConnection },
      } = await client.query<{
        salesforceConnection: {
          lastUpdate: string;
          salesforceConnectionSalesforceLogRelation: {
            items: Array<{
              id: string;
              createdAt: string;
              level: string;
              message: string;
            }>;
          };
        };
      }>({
        query: SALESFORCE_LOG_LIST_QUERY,
        variables: { id: salesforceConnectionId },
        fetchPolicy: 'network-only',
      });

      const {
        lastUpdate,
        salesforceConnectionSalesforceLogRelation: salesforceLogs,
      } = salesforceConnection;

      updateSalesforceConnectionLastUpdate(lastUpdate);

      salesforceLogsEvent.dispatch(salesforceLogs.items);
      return salesforceLogs.items;
    } catch (error) {
      console.log('fetchSalesforceLogs:error', error);
      salesforceLogsError.dispatch(error);
    }
  }
  return null;
};

/**
 * Updates SalesforceConnection on 8base.
 *
 * @param data - Salesforce data input to update.
 * @returns - Salesforce connection updated data.
 */
const update8baseSalesforceConnection = async (
  data: SalesforceConnectionUpdateInput,
): Promise<SalesforceConnection> => {
  const client = apolloClient;

  const {
    data: { salesforceConnectionUpdate },
  } = await client.mutate({
    mutation: SALESFORCE_CONNECTION_UPDATE_MUTATION,
    variables: { data },
  });

  return salesforceConnectionUpdate;
};

export const updateSalesforceConnection = async (
  updatedData: SalesforceConnectionUpdateInput,
  updateStore = true,
): Promise<SalesforceConnection> => {
  // The salesforce stored connection data can come from either of this sources
  const salesforceConnectionEventState = salesforceConnectedEvent.get();

  if (!salesforceConnectionEventState) {
    throw new Error('salesforce store: No stored connection has been found');
  }

  const {
    salesforceConnection,
    ...salesforceConnectionEvent
  } = salesforceConnectionEventState;

  const data = {
    id: salesforceConnection.id,
    ...updatedData,
  };

  try {
    const updatedSalesforceConnection = await update8baseSalesforceConnection(
      data,
    );
    console.log(
      'updateSalesforceConnection:updatedSalesforceConnection',
      updatedSalesforceConnection,
    );

    if (updateStore) {
      const updatedSalesforceConnectionEvent = {
        ...salesforceConnectionEvent,
        salesforceConnection: updatedSalesforceConnection,
      };

      salesforceConnectedEvent.dispatch(
        updatedSalesforceConnectionEvent as SalesforceConnectedEventType,
      );
    }

    return updatedSalesforceConnection;
  } catch (error) {
    console.error('updateSalesforceConnection:error', error);
    throw error;
  }
};

export const disconnectSalesforce = async (): Promise<void> => {
  try {
    await updateSalesforceConnection(
      { status: SALESFORCE_CONNECTION_STATUS_INACTIVE },
      false,
    );

    // salesforceDisconnectedEvent.dispatch(null);
    salesforceConnectedEvent.dispatch(null);
  } catch (error) {
    console.error('fetchSalesforceConnection:error', error);
    // salesforceDisconnectedError.dispatch(error);
  }
};

export const salesforceManualUpdate = async (): Promise<{
  success: boolean;
} | null> => {
  const client = apolloClient;
  const salesforce = salesforceConnectedEvent.get();

  let response;
  try {
    response = await client.mutate({
      mutation: SALESFORCE_UPDATE_NOW_MUTATION,
      variables: { id: salesforce?.salesforceConnection.id },
    });
  } catch (error) {
    console.log('salesforceManualUpdate:error', error);
    salesforceUpdateNowErrorEvent.dispatch(error);
    return null;
  }

  const { salesforceUpdateNow } = response.data;

  console.log(
    'salesforceManualUpdate:salesforceUpdateNow',
    salesforceUpdateNow,
  );

  salesforceUpdateNowEvent.dispatch(salesforceUpdateNow);
  return salesforceUpdateNow;
};

/**
 * From Entries to Object conversion.
 *
 * @param {[][]} array - Entries.
 * @returns {object} - Object from entries.
 */
const fromEntries = (array: [string, unknown][]): Record<string, unknown> =>
  array.reduce((obj: Record<string, unknown>, [key, val]) => {
    // eslint-disable-next-line no-param-reassign
    obj[key] = val;
    return obj;
  }, {});

/**
 * Flat salesforce object.
 *
 * @param object -
 * @returns -
 */
const flatObject = (
  object: Record<string, unknown>[] | Record<string, unknown>,
): Record<string, unknown> => {
  const flatten: [string, unknown][] = [];
  Object.entries(object).forEach((field) => {
    const [key, value] = field;
    if (typeof value === 'object' && value) {
      const subFlattenObject = flatObject(value as Record<string, unknown>);
      const renameFieldsFlattenObject = Object.entries(subFlattenObject).map(
        (subField) => {
          // eslint-disable-next-line no-param-reassign
          subField[0] = `${key}_${subField[0]}`;
          return subField;
        },
      );
      flatten.push(...renameFieldsFlattenObject);
    } else {
      flatten.push(field);
    }
  });

  return fromEntries(flatten);
};

export const fetchSalesforceObject = (data: {
  subject: string;
  query: string;
  limit?: number;
  connection?: jsforce.Connection;
}): Promise<unknown[][]> => {
  const salesforce = salesforceConnectedEvent.get();

  const {
    subject,
    query,
    limit = 10000,
    connection = salesforce?.connection,
  } = data;

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const queryObject = connection?.sobject(subject).select(query).limit(limit);

    queryObject?.execute({}, (err, records) => {
      if (err) {
        reject(err);
        return;
      }
      console.log('fetchSalesforceObject: ', subject, records);
      resolve(records);
    });
  });
};

type FetchOptions = {
  limit?: number;
  connection?: jsforce.Connection;
  filter?: boolean;
};

/**
 * Fetch Opportunities from Salesforce.
 *
 * @param {{limit?: number, filter?:boolean, connection?:object}} options - Query Options.
 * @returns {Promise<object[]>} - Array of opportunities.
 */
export const fetchOpportunities = (
  options: FetchOptions,
): Promise<unknown[][]> =>
  fetchSalesforceObject({
    ...options,
    subject: 'Opportunity',
    query: '*, Account.Name, Account.Type, Account.Phone, Owner.Name',
  });

/**
 * Fetch Leads from Salesforce.
 *
 * @param {{limit?: number, filter?:boolean, connection?:object}} options - Query Options.
 * @returns {Promise<object[]>} - Array of leads.
 */
export const fetchLeads = (options: FetchOptions): Promise<unknown[][]> =>
  fetchSalesforceObject({
    ...options,
    subject: 'Lead',
    query: '*, Owner.Username, Owner.Email, Owner.Name',
  });

export const fetchSalesforceObjectField = async (): Promise<{
  opportunity: string[];
} | null> => {
  try {
    const salesforce = salesforceConnectedEvent.get();

    if (salesforce === null) {
      return null;
    }

    let opportunities = await fetchOpportunities({ limit: 1 });

    if (!opportunities.length) {
      opportunities = await fetchOpportunities({ limit: 1, filter: false });
    }

    const opportunityObject =
      ((opportunities[0] as unknown) as Record<string, unknown>) || {};

    const opportunityKeys = Object.keys(flatObject(opportunityObject)).filter(
      (field) => !SALESFORCE_OPPORTUNITY_REMOVE_FIELDS.includes(field),
    );

    const objectFields = { opportunity: opportunityKeys };

    salesforceObjectFieldsEvent.dispatch(objectFields);
    return objectFields;
  } catch (error) {
    salesforceObjectFieldsErrorEvent.dispatch(error);
    console.log(salesforceObjectFieldsErrorEvent, error);
    return null;
  }
};

export const fetchSalesforceFieldMappings = async (): Promise<Array<{
  id: string;
  object: string;
  leadField: string;
  salesforceField: string;
}> | null> => {
  const client = apolloClient;
  const session = OnSession.get();
  const selectedCompany = session?.selectedCompany;

  const filter = {
    company: { id: { equals: selectedCompany?.id } },
  };

  try {
    const {
      data: { salesforceFieldMappingsList },
    } = await client.query<{
      salesforceFieldMappingsList: {
        items: Array<{
          id: string;
          object: string;
          leadField: string;
          salesforceField: string;
        }>;
      };
    }>({
      query: SALESFORCE_FIELD_MAPPING_LIST_QUERY,
      variables: { filter },
      fetchPolicy: 'network-only',
    });
    console.log(
      'fetchSalesforceFieldMappings:salesforceFieldMappingsList',
      salesforceFieldMappingsList,
    );

    salesforceFieldMappingsEvent.dispatch(salesforceFieldMappingsList.items);
    return salesforceFieldMappingsList.items;
  } catch (error) {
    console.error('fetchSalesforceFieldMappings:error', error);
    salesforceFieldMappingsErrorEvent.dispatch(error);
    return null;
  }
};

export const deleteFieldMapping = async (fieldMapping: {
  id: string;
  object: string;
  leadField: string;
  salesforceField: string;
}): Promise<{ success: string } | null> => {
  const client = apolloClient;
  const { id } = fieldMapping;

  const data = { id, force: true };

  try {
    const {
      data: { salesforceFieldMappingDelete },
    } = await client.mutate({
      mutation: SALESFORCE_FIELD_MAPPING_DELETE_MUTATION,
      variables: { data },
    });
    console.log(
      'deleteFieldMapping:salesforceFieldMappingDelete',
      salesforceFieldMappingDelete,
    );

    // Update Stored Field Mappings, removed the one deleted
    const fieldMappings = salesforceFieldMappingsEvent.get();
    const updatedFieldMappings = fieldMappings?.filter(
      (storedFieldMapping) => storedFieldMapping.id !== fieldMapping.id,
    );

    salesforceFieldMappingDeleteEvent.dispatch(salesforceFieldMappingDelete);
    salesforceFieldMappingsEvent.dispatch(
      updatedFieldMappings as SalesforceMappingType[],
    );
    return salesforceFieldMappingDelete;
  } catch (error) {
    console.error('deleteFieldMapping:error', error);
    salesforceFieldMappingDeleteErrorEvent.dispatch(error);
    return null;
  }
};
export const isEqualSalesforceFieldMapping = (
  fieldMapping1: {
    id?: string;
    object?: string;
    leadField?: string;
    salesforceField?: string;
  },
  fieldMapping2: {
    id?: string;
    object?: string;
    leadField?: string;
    salesforceField?: string;
  },
): boolean =>
  fieldMapping1.object === fieldMapping2.object &&
  fieldMapping1.leadField === fieldMapping2.leadField;

export const updateFieldMappings = async (
  fieldMappings: Array<{
    id: string;
    object: string;
    leadField: string;
    salesforceField: string;
  }>,
): Promise<void> => {
  const client = apolloClient;
  const storedFieldMappings = salesforceFieldMappingsEvent.get();

  // Validate
  const isDuplicated = fieldMappings?.some((updatedFieldMapping) => {
    const storedModifiedFieldMapping = storedFieldMappings?.find(
      (storedFieldMapping) => storedFieldMapping.id === updatedFieldMapping.id,
    );
    const completeUpdatedFieldMapping = {
      ...storedModifiedFieldMapping,
      ...updatedFieldMapping,
    };
    return storedFieldMappings
      ?.filter(
        (storedFieldMapping) =>
          storedFieldMapping.id !== updatedFieldMapping.id,
      )
      .some((storedFieldMapping) =>
        isEqualSalesforceFieldMapping(
          storedFieldMapping,
          completeUpdatedFieldMapping,
        ),
      );
  });
  if (isDuplicated) {
    salesforceFieldMappingUpdateErrorEvent.dispatch(
      new Error('2 Salesforce field mapping cannot have the same lead field'),
    );
  }

  // Update All Fields
  const updatePromises = fieldMappings.map(async (fieldMapping) => {
    const {
      data: { salesforceFieldMappingUpdate },
    } = await client.mutate({
      mutation: SALESFORCE_FIELD_MAPPING_UPDATE_MUTATION,
      variables: {
        data: {
          id: fieldMapping.id,
          object: fieldMapping.object,
          leadField: fieldMapping.leadField,
          salesforceField: fieldMapping.salesforceField,
        },
      },
    });
    console.log(
      'updateFieldMappings:salesforceFieldMappingUpdate',
      salesforceFieldMappingUpdate,
    );

    return salesforceFieldMappingUpdate;
  });

  try {
    const updatedFieldMappings = await Promise.all(updatePromises);
    salesforceFieldMappingUpdateEvent.dispatch(
      (updatedFieldMappings as unknown) as SalesforceMappingType,
    );
  } catch (error) {
    console.error('deleteFieldMapping:error', error);
    salesforceFieldMappingUpdateErrorEvent.dispatch(error);
  }
};

export const isSalesforceFieldMappingValid = (fieldMapping: {
  object?: string;
  leadField?: string;
  salesforceField?: string;
}): [boolean, string] => {
  if (!isValidString(fieldMapping.object))
    return [false, 'Please enter the object field'];

  if (!isValidString(fieldMapping.leadField))
    return [false, 'Please enter the deal field'];

  if (!isValidString(fieldMapping.salesforceField))
    return [false, 'Please enter the salesforce field'];

  return [true, ''];
};

export const createFieldMapping = async (fieldMapping: {
  object?: string;
  leadField?: string;
  salesforceField?: string;
}): Promise<{
  id: string;
  object: string;
  leadField: string;
  salesforceField: string;
} | null> => {
  const client = apolloClient;
  const session = OnSession.get();
  const selectedCompany = session?.selectedCompany;

  const fieldMappings = salesforceFieldMappingsEvent.get();
  // Validate

  const [isValid, message] = isSalesforceFieldMappingValid(fieldMapping);
  if (!isValid) {
    salesforceFieldMappingCreateErrorEvent.dispatch(new Error(message));
    return null;
  }

  const isDuplicated = fieldMappings?.some((storedFieldMapping) =>
    isEqualSalesforceFieldMapping(fieldMapping, storedFieldMapping),
  );
  if (isDuplicated) {
    salesforceFieldMappingCreateErrorEvent.dispatch(
      new Error('This deal field already exists'),
    );
    return null;
  }

  // Create

  const data = {
    company: { connect: { id: selectedCompany?.id } },
    ...fieldMapping,
  };

  try {
    const {
      data: { salesforceFieldMappingCreate },
    } = await client.mutate({
      mutation: SALESFORCE_FIELD_MAPPING_CREATE_MUTATION,
      variables: { data },
    });
    console.log(
      'deleteFieldMapping:salesforceFieldMappingDelete',
      salesforceFieldMappingCreate,
    );

    // Update Stored Field Mappings, add the one created.
    if (fieldMappings) {
      const newFieldMappings = [...fieldMappings, salesforceFieldMappingCreate];
      salesforceFieldMappingsEvent.dispatch(newFieldMappings);
    }

    salesforceFieldMappingCreateEvent.dispatch(salesforceFieldMappingCreate);
    return salesforceFieldMappingCreate;
  } catch (error) {
    console.error('deleteFieldMapping:error', error);
    salesforceFieldMappingCreateErrorEvent.dispatch(error);
    return null;
  }
};
