import gql from 'graphql-tag';

export const companyAllFragment = gql`
  fragment companyAllFragment on Company {
    id
    name
    status
    taxId
    companyTeamRelation {
      items {
        id
        name
      }
      count
    }
    invitationCompanyRelation(filter: { status: { equals: "PENDING" } }) {
      count
    }
    companyInvitationAdminRelation(filter: { status: { equals: "PENDING" } }) {
      count
    }
    companySubscriptionHistoryRelation(first: 1, sort: { createdAt: DESC }) {
      items {
        id
        status
        cancel_at_period_end
        stripeCustomerId
        stripeSubscriptionId
        expireDate
        pendingUpdate
        seats
        coupon
        plan {
          id
          name
          description
          isFreeTrial
          stripeId
          planPlanPriceRelation {
            items {
              id
              name
              type
              price
              stripeId
            }
          }
        }
      }
    }
    companyPaymentMethodRelation(first: 1, sort: { createdAt: DESC }) {
      items {
        id
        cardHolderName
        expYear
        expMonth
        last4
        addressLine1
        addressLine2
        city
        state
        stripeId
      }
    }
    companyCompanyUserRelation {
      count
      items {
        id
        user {
          id
        }
        role
      }
    }
  }
`;

export const CREATE_COMPANY_MUTATION = gql`
  mutation CreateCompany($data: CompanyCreateInput!) {
    companyCreate(data: $data) {
      id
      name
      taxId
      companyTeamRelation {
        items {
          id
          name
        }
      }
    }
  }
`;

export const companyFragment = gql`
  fragment companyFragment on Company {
    id
    name
    companyTeamRelation {
      items {
        id
        name
      }
    }
    companySubscriptionHistoryRelation(first: 1, sort: { createdAt: DESC }) {
      items {
        id
        status
        stripeCustomerId
        stripeSubscriptionId
        expireDate
        pendingUpdate
        plan {
          id
          name
          description
          isFreeTrial
          stripeId
          planPlanPriceRelation {
            items {
              id
              name
              type
              price
              stripeId
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation($data: CompanyUpdateInput!, $filter: CompanyKeyFilter) {
    companyUpdate(data: $data, filter: $filter) {
      ...companyFragment
    }
  }
  ${companyFragment}
`;

export const SUBSCRIPTION_PLANS_QUERY = gql`
  query($filter: PlanFilter) {
    plansList(filter: $filter, sort: { name: ASC }) {
      count
      items {
        id
        name
        description
        isFreeTrial
        stripeId
        planPlanPriceRelation {
          items {
            id
            name
            type
            price
            stripeId
          }
        }
      }
    }
  }
`;

export const FETCH_COMPANY_FOR_BILLING_STATUS = gql`
  query($id: ID) {
    company(id: $id) {
      ...companyAllFragment
    }
  }
  ${companyAllFragment}
`;

export const COMPANY_BILLING_SUBSCRIPTION = gql`
  subscription($id: ID) {
    Company(filter: { mutation_in: [update], node: { id: { equals: $id } } }) {
      node {
        ...companyAllFragment
      }
      mutation
    }
  }
  ${companyAllFragment}
`;

export const SELECT_COMPANY_MUTATION = gql`
  mutation($user: ID!, $company: ID!) {
    userUpdate(
      filter: { id: $user }
      data: { selectedCompany: { connect: { id: $company } } }
    ) {
      id
      selectedCompany {
        id
      }
    }
  }
`;
