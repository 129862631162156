import { useAuth0 } from '@auth0/auth0-react';
import { useEvent, useSubscription } from '@cobuildlab/react-simple-state';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FIXED_ROUTES } from '../../shared/constants/menu-routes';
import { OnInvitationLoading } from '../invitation/invitation-events';
import { fetchSession, sessionSubscription } from './session-actions';
import { OnSession } from './session-events';
import { Session, UserSession } from './session-types';
import { checkRouteRules, mapSessionState } from './session-utils';

/**
 * @param {boolean} loading - To start fetching or not.
 * @returns  {[Session,boolean]} Loading - To start fetching or not.
 */
export function useSetupSession(loading: boolean): [Session, boolean] {
  const [session, setSession] = useState<UserSession>();
  const [loadingSession, setLoadingSession] = useState<boolean>(true);

  useSubscription(OnSession, (data) => {
    setSession(data as UserSession);
    if (loadingSession) {
      setLoadingSession(false);
    }
  });

  useEffect(() => {
    if (!session?.id) {
      return () => {};
    }
    const subscription = sessionSubscription(
      { user: session?.id },
      (subData) => {
        const currentSession = OnSession.get() as UserSession;
        const { userCompanyUserRelation } = subData?.Users.node || {};
        if (subData?.Users.node.id !== currentSession.id) {
          console.error('error en la suscriptions');
          return;
        }
        const newData = subData?.Users.updatedFields.reduce(
          (prevuis, key) => ({ ...prevuis, [key]: subData.Users.node[key] }),
          currentSession,
        );

        const newSession = userCompanyUserRelation
          ? { ...newData, userCompanyUserRelation }
          : newData;
        OnSession.dispatch(newSession as UserSession);
      },
    );

    return subscription.unsubscribe;
  }, [session?.id]);
  useEffect(() => {
    if (loading) {
      return;
    }
    setLoadingSession(true);
    fetchSession();
  }, [loading]);
  // const loadingIvitations = useEvent(OnInvitationLoading);
  const sessionData = mapSessionState(session as UserSession);

  return [sessionData as Session, loadingSession || loading];
}

/**
 * Hook taht returns session state
 * WARNING: This hook should be used inside a component or hook as a child of  Session component.
 * Otherwise could lead to a null value.
 *
 * @returns {Session} Session object.
 */
export function useSession(): Session {
  const session = useEvent(OnSession, {
    reducer: (data) => mapSessionState(data),
  });
  return session as Session;
}

/**
 *
 * @param {Session} data - Session data.
 * @param {boolean} loading - Loading for session.
 * @returns {[boolean, string]} - Array to check render and redirect path.
 */
export function useSessionRedirects(
  data: Session,
  loading: boolean,
): [boolean, string] {
  const history = useHistory();
  const auth = useAuth0();
  const location = history.location.pathname;
  const loadingInvitation = useEvent(OnInvitationLoading);
  // If user is not Authenticated redirect to Auth view
  if (!auth.isAuthenticated) {
    return [false, FIXED_ROUTES.AUTH];
  }
  // If the session is Loading keep render the childs
  if (loading) {
    return [true, ''];
  }
  // Get from routes rules
  const state = checkRouteRules(data, location, loadingInvitation);

  return state;
}
